import bitebybiteConfig from './bitebybite';
import defaultConfig from './default';
import nocConfig from './noc';
import tunglanConfig from './tunglan';

const merchants = {
  biteByBite: bitebybiteConfig,
  noc: nocConfig,
  tungLan: tunglanConfig,
};

export default merchants[process.env.REACT_APP_MERCHANT] || defaultConfig;
